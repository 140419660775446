.exportModal {
  .mergeFlagWrapper {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 16px;
    padding-left: 14px;
    background: #fff;
    border: 1px solid #e2e2e5;
    border-radius: 2px;
  }

  .fileNameWrapper {
    margin-bottom: 20px;

    .fileNameInput {
      width: 300px;
    }
  }

  .table {
    .operationBtn {
      margin-left: 0;
      padding-left: 0;
    }

    .templateColumnSelect {
      width: 300px;
    }
  }

  .newBtn {
    width: 100%;
    margin-top: 12px;
  }
}

.exportCenterLink {
  color: #1890ff;
  cursor: pointer;
}

.popover {
  max-width: 600px;

  .popoverTags {
    margin-bottom: 4px;
  }
}

.redTips {
  height: 16px;
  margin-left: 70px;
  color: #ff4d4f;
  font-size: 14px;
}
