.exportList {
  .vendorOptBtns {
    padding-left: 0;
  }

  :global {
    .c1wupbe700-canary49[aria-selected="true"] {
      box-shadow: none;
    }
  }
}

.layout {
  height: 100vh;
  overflow: hidden;
}

.clgreen {
  color: #02c190;
}

.clred {
  color: #f2270a;
}

.warning {
  color: #faad14;
}

.progress {
  width: 70px;
}
